import {Box} from '@chakra-ui/react'
import React from 'react'
import {Footer, Header, TituloPagina} from '../../Components';
import { ConfiguracoesEmpresa, ConfiguracoesSenha, ConfiguracoesSMTP } from './Complementos';



function Configuracoes() {


  return (
    <Box>
        <Header pagina="Dashboard"/> 
        <TituloPagina titulo="Configurações" descricao="Reorganize e edite suas definições e so sistema a seu gosto!"/>
        
          <Box mt={10} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <ConfiguracoesEmpresa/>
              <ConfiguracoesSMTP/>             
              <ConfiguracoesSenha/>          
          </Box>

        <Footer/>
    </Box>
  )
}

export default Configuracoes
