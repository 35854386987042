import React from 'react'
import { Link } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr, Show, Tag, Center, Alert, AlertIcon } from '@chakra-ui/react'
import {PeriodosDetalhes} from '..';
import { useGetTokenLogin } from '../../../../Hooks';
import { RequestAPI } from '../../../../Configurations';
import useSWR from 'swr';
import { SkeletonLoader } from '../../../../Components';
import { useStorePeriodos } from '../../../../Stores';

function PeriodosLista({filtro, detalhes}) {

  const getValueLogin = useGetTokenLogin();
  const {periodos} = useStorePeriodos()

  const fetcher = async (url) =>{
    const {data} = await RequestAPI.get(url)

    return data
  }

  const { data, error, isLoading } = useSWR(`/pagamentos/entidade/${getValueLogin.numero_entidade}/periodo/${filtro}/data/presente`, fetcher)

  const result = periodos.length ? periodos : data?.mensagem.pagamentos

  detalhes(data?.mensagem)
  console.log(data?.mensagem)

  return (
    error ? <Center>
      <Alert p={[5]} status={"error"}  width={["xs","lg","3xl"]} variant='left-accent'>
      <AlertIcon />
      Não conseguimos carregar suas informações, por favor recarregue a pagina!
      </Alert>
      </Center>
      : isLoading ? <SkeletonLoader/> :
    <Table variant='simple' fontSize={[12,14,16]}>
              <Thead>
                <Tr>
                  <Th fontSize={[12,14,16]}>Referência</Th>
                  <Th fontSize={[12,14,16]}>Montante</Th>
                  <Show above='sm'>
                  <Th fontSize={[12,14,16]}>Vezes</Th>
                  </Show>
                  <Th fontSize={[12,14,16]}>Mais</Th>
                </Tr>
              </Thead>
              <Tbody>
                {result.map((value,i) => <Tr key={i}>
                  <Td _hover={{color:"#006672"}}><Link to={`/movimentos/referenciado/${value.referencia_do_servico}`}>{value.referencia_do_servico}</Link></Td>
                  <Td fontWeight={400}><Tag colorScheme='green'>AKZ {Intl.NumberFormat('PT-br').format(value.somatorio)}</Tag></Td>
                  <Show above='sm'>
                  <Td>{value.quantidade}X</Td>
                  </Show>
                  <Td>
                    <PeriodosDetalhes data={{...value}}/>
                  </Td>
                </Tr>
              )}
              </Tbody>
            </Table>
  )
}

export default PeriodosLista
