import React from 'react'
import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Input, Text } from '@chakra-ui/react'
import { DrawerView } from '../../../../Components'
import { MdOutlineAddCard, MdSave } from 'react-icons/md'
import { useFormik } from 'formik'
import { FileUploader } from 'react-drag-drop-files'

const fileTypes = ["CSV", "JSON", "XLS"];

function ReferenciasGerar({margin, top}) {

  const formik = useFormik({
    initialValues:{
      num_referencia:'',
        data_limite_pagamento:'',
        estado_atm:'Activo',
        montante_fixo:''
    },
    onSubmit: async (values)=> {

    },
  })

  
  const handleChange = (file) => {

    setTimeout(() =>{
      console.log(file)
    }, 2000)
  };


  return (
    <DrawerView Titulo={"Gerar referências"} tamanho={"md"} lado={"left"} Botao={<Button bg="#006672" color="#FFFFFF" size={["sm","md"]} fontWeight={300} mb={top ? top : 0} ml={margin ? margin : 5} leftIcon={<MdOutlineAddCard/>}> Gerar</Button>}>
                  <form onSubmit={formik.handleSubmit}>
                    <Box>
                      
                      <Flex>
                        <FormControl mt={4} flexBasis={"100vw"}>
                              <FormLabel>Referência</FormLabel>
                              <Input type='text' flex={1}  name='num_referencia'  required onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                        <FormControl mt={4} ml={4}>
                              <FormLabel>&nbsp;</FormLabel>
                              <Button>Sortear</Button>
                        </FormControl>
                      </Flex>
                      <FormControl mt={4}>
                          <FormLabel>Limite de pagamento</FormLabel>
                            <Input type='date'  name='data_limite_pagamento' required onChange={formik.handleChange} value={formik.values.data_limite_pagamento} />
                      </FormControl>
                      <Flex>
                          <FormControl mt={4}>
                            <FormLabel>Valor á pagar</FormLabel>
                            <Input type='number'  name='montante_fixo' onChange={formik.handleChange} value={formik.values.montante_fixo} />                              
                          </FormControl>
                      </Flex>
                      
                      <Button leftIcon={<MdSave/>} bg="#006672" color="#FFFFFF"  type='submit' mt={5}>Salvar</Button>
                  
                  </Box> 
                </form>

              <Box mt={5}>
                  <Box px={10} py={5}>
                    <Divider></Divider>
                  </Box>
                  <Heading size={["lg"]}>Importe suas referências</Heading>
                  <Text fontSize={[13,14]}>Poderá importar suas referências de um ficheiro em CSV</Text>
                  <Box mt={6} fontSize={16} fontWeight={300}>

                      <FileUploader handleChange={handleChange} name="file" types={fileTypes} />

                      <Alert
                        status='success'
                        variant='left-accent'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        textAlign='center'
                        height='200px'
                        mt={5}
                      >
                        <AlertIcon boxSize='50px' mr={0} />
                        <AlertTitle mt={4} mb={1} fontSize='lg'>
                          Arquivo importado!
                        </AlertTitle>
                        <AlertDescription maxWidth='sm'>
                          Recebemos as referências com sucesso. Aguarde o processo
                        </AlertDescription>
                      </Alert>
                  
                  </Box>
              </Box>
                      
    </DrawerView>
  )
}

export default ReferenciasGerar
