import React from 'react'
import {Box, Button, Flex, FormControl, FormLabel, Heading, Input } from '@chakra-ui/react'
import { MdLock, MdSave } from 'react-icons/md';
import { useFormik } from 'formik';
function ConfiguracoesSenha() {

    const formik = useFormik({
        initialValues:{
          num_referencia:'',
            data_limite_pagamento:'',
            estado_atm:'Activo',
            montante_fixo:''
        },
        onSubmit: async (values)=> {
    
        },
      })

  return (
    <Box mt={10} px={[10]} w={["100%","40%","70%"]}>
                <Heading display="flex" alignItems="center" fontSize={20}><MdLock size={30} /> &nbsp;&nbsp;&nbsp;&nbsp;Alterar a senha</Heading>       
                <form onSubmit={formik.handleSubmit}>
                    <Box alignItems="center" display="flex" flexDirection="column">
                      
                      <Flex w={["100%","80%","70%"]} mt={[0,5]} flexDirection={["column","row"]}>
                        <FormControl mt={4}>
                              <FormLabel>Senha Antiga</FormLabel>
                              <Input type='text' flex={1}  name='num_referencia'  required onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                      </Flex>
                      <Flex w={["100%","60%","70%"]} mt={[5,5]} flexDirection={["column","row"]}>
                        <FormControl mt={4} >
                              <FormLabel>Nova Senha</FormLabel>
                              <Input type='text' flex={1}  name='num_referencia'  required onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                        <FormControl mt={4} ml={[0,4]}>
                              <FormLabel>Confirme sua senha</FormLabel>
                              <Input type='text' flex={1}  name='num_referencia'  required onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                      </Flex>
                      
                      <Button leftIcon={<MdSave/>} bg="#006672" color="#FFFFFF"  type='submit' mt={[5,5]}>Salvar</Button>
                  
                  </Box> 
                </form>            
                
             </Box>
  )
}

export default ConfiguracoesSenha
