import { Box, Button, Flex, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Select } from '@chakra-ui/react'
import React from 'react'
import { MdRefresh } from 'react-icons/md'

function PeriodosPaginacao() {
  return (
    <Flex>
      <Select placeholder='Deseja ver quantos?' w="20%">
            <option value='10' selected >10</option>
            <option value='100'>100</option>
            <option value='500'>500</option>
            <option value='1000'>1000</option>
          </Select>
          <Box>
            <NumberInput
              name='pagina'
              value={3}
              min={1}
              max={10}
              width={120}
              ml={5}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            </Box>
          <Button bg="#006672" color="#FFFFFF" fontWeight={300} leftIcon={<MdRefresh/>} mb={10} ml={5}>Carregar</Button>
    </Flex>
  )
}

export default PeriodosPaginacao
