import { Alert, AlertIcon, Button, Flex, Heading, Input, ScaleFade, Stack, Text} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdPassword, MdSupervisedUserCircle } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { LogoMarca, MudarTema, Footer } from '../../Components'
import Constants from '../../Configurations/Breakpoint'
import { useFormik } from 'formik'
import { useSetTokenLogin, useGetTokenLogin } from '../../Hooks'
import { RequestAPI } from '../../Configurations'


function Login() {
  const [color, setColor] = useState('')
  const [loader, setLoader] = useState(false)
  const [textDisplay, setTextDisplay] = useState({status:"loading", text:"Aguarde o processo!"}) 
  const navigate =  useNavigate()
  const getValueLogin = useGetTokenLogin();
  const setValueLogin = useSetTokenLogin;
  
  const formik = useFormik({
    initialValues:{
      email:'',
      senha:''
    },
    onSubmit: async (values)=> {
      
          try {
          
          setLoader(true)   

          const {data:{status, mensagem}} =  await RequestAPI.post(`/clientes/login`,values)
          
          if(status === "sucesso"){
          
            setTextDisplay({status:"success", text: `Seja bem-vindo! ${mensagem.nome_empresa}`})
            setValueLogin(mensagem, 7)
            navigate('/dashboard')
            
            
          }else{
            setTextDisplay({status:"error", text: mensagem})
        
          }

          
        
      }catch (error) {
        console.log(error)
        setTextDisplay({status:"error", text:"Não conseguimos ter sucesso no processo, Tente outra vez!"})
      }
      
    },
  })
  
  useEffect(()=>{
      if(getValueLogin?.numero_entidade){
          navigate('/dashboard')
      }
  },[getValueLogin, navigate])

  return (
    <Flex w="100%" h="100vh" justifyContent="center" alignItems="center" flexDirection="column">
       <MudarTema colorText={setColor}/>
       <LogoMarca/>
       <Stack spacing={4} w={Constants.breakpointsTelas} h={400} p={10} rounded={10} color={color}>
            <form onSubmit={formik.handleSubmit}>
                <Heading textAlign="center" size="2xl" fontWeight={900}>BEM VINDO</Heading>
                <Text mb={10} textAlign="center" fontWeight={200}>Coloque suas  credencias para o acesso</Text>

                <Flex justifyContent="space-between" alignItems="center">
                    <MdSupervisedUserCircle size={40} color={'#006672'}/>
                    <Input type='email' onChange={formik.handleChange} required name='email' value={formik.values.email}  fontWeight={900} placeholder='Introduza seu email' p={3} _placeholder={{color:color}} rounded={20} ml={5} border={`3px solid #006672`} />
                </Flex>

                <Flex justifyContent="space-between" alignItems="center" mt={5}>
                    <MdPassword size={40} color={'#006672'}/>
                    <Input type='password' onChange={formik.handleChange} required name='senha' value={formik.values.senha} fontWeight={900} placeholder='Introduza sua senha' p={3} _placeholder={{color:color}} rounded={20} ml={5} border={`3px solid #006672`} />
                </Flex>

                <Button type='submit' shadow="lg" mt={5} w="100%" isDisabled={loader} color="#fff" bg={'#006672'} rounded={20}>Autenticar</Button>
            </form>
            <Link style={{textAlign:"center"}} to={"/esqueci-a-senha"}>Esqueci minha senha</Link>
        </Stack>
        {loader && <ScaleFade initialScale={0.9} in={loader} transition={{ exit: { delay: 1 }, enter: { duration: 0.5 } }}>
          <Alert p={[5]} status={textDisplay.status}  width={["xs","lg","2xl"]} variant='left-accent'>
            <AlertIcon />
            {textDisplay.text}
          </Alert>
        </ScaleFade>}
        <Footer/>
    </Flex>
  )
}

export default Login
