import React from 'react'
import Constants from '../../Configurations/Breakpoint'
import { Text } from '@chakra-ui/react'

function Footer() {
  return (
    <Text fontSize={Constants.breakpointsTextos} textAlign="center" p={5}>
      &#169; Copyright, Todos os direitos reservados - Desenvolvido pela <b>KLUMANCASI-SERVIÇOS E TECNOLOGIAS, LDA</b>
    </Text>
  )
}

export default Footer
