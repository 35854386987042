import { Alert, AlertIcon, Button, Flex, Heading, Input, ScaleFade, Stack, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdEmail } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import { Footer, LogoMarca, MudarTema } from '../../Components'
import Constants from '../../Configurations/Breakpoint'
import { useFormik } from 'formik'
import { RequestAPI } from '../../Configurations'


function EsqueciSenha() {
  const [color, setColor] = useState('')
  const [loader, setLoader] = useState(false)
  const [textDisplay, setTextDisplay] = useState({status:"loading", text:"Aguarde o processo!"}) 
  const navigate =  useNavigate()

  const formik = useFormik({
    initialValues:{
      email:'',
      canal:'E-mail'
    },
    onSubmit: async (values)=> {
      
          try {
          
          setLoader(true)   
          
          if(!values.email) {

            setTextDisplay({status:"error", text:"Não temos o seu email!"})
          }

          const {data:{status, mensagem}} =  await RequestAPI.post(`/clientes/codigo-seguranca/recuperar`,values)
          
          if(status === "sucesso"){
          
            setTextDisplay({status:"success", text: `Seja bem-vindo! ${mensagem.nome_empresa}`})
            navigate('/codigo-seguranca')
            
            
          }else{
            setTextDisplay({status:"error", text: mensagem})
        
          }

          setLoader(false)
        
      }catch (error) {
        console.log(error)
        setTextDisplay({status:"error", text:"Não conseguimos ter sucesso no processo, Tente outra vez!"})
        setLoader(false)
      }
      
    },
  })

  return (
    <Flex w="100%" h="100vh" justifyContent="center" alignItems="center" flexDirection="column">
       <MudarTema colorText={setColor}/>
       <LogoMarca/>
       <Stack spacing={4} w={Constants.breakpointsTelas} h={400} p={10} rounded={10} color={color}>
            <form onSubmit={formik.handleSubmit}>
                <Heading textAlign="center" size="2xl" fontWeight={900}>ESQUECI A SENHA</Heading>
                <Text mb={10} mt={2} textAlign="center" fontWeight={200}>Enviaremos um código de acesso  em seu email</Text>
                <Flex justifyContent="space-between" alignItems="center">
                    <MdEmail size={40} color='#006672'/>
                    <Input type='email' onChange={formik.handleChange} required name='email' value={formik.values.email} fontWeight={900} placeholder='Introduza seu email' p={3} rounded={20} ml={5} border="3px solid #006672" />
                </Flex>

                <Button type='submit' shadow="lg" mt={5} w="100%" isDisabled={loader} color="#fff" bg={'#006672'} rounded={20}>Receber o código</Button>
            </form>
            <Link to={"/"} style={{textAlign:"center"}} >Voltar ao login</Link>
        </Stack>
        {loader && <ScaleFade initialScale={0.9} in={loader} transition={{ exit: { delay: 1 }, enter: { duration: 0.5 } }}>
          <Alert p={[5]} status={textDisplay.status}  width={["xs","lg","2xl"]} variant='left-accent'>
            <AlertIcon />
            {textDisplay.text}
          </Alert>
        </ScaleFade>}
       
        <Footer/>
    </Flex>
  )
}

export default EsqueciSenha
