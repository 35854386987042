import { Flex, Heading, Stack, Text} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Footer, LogoMarca } from '../../Components'
import Constants from '../../Configurations/Breakpoint'


function Erro404() {
  const [color] = useState('')
  

  return (
    <Flex w="100%" h="100vh" justifyContent="center" alignItems="center" flexDirection="column">
       <LogoMarca/>
       <Stack spacing={4} w={Constants.breakpointsTelas} h={400} p={10} mb={20} rounded={10} textAlign="center" color={color}>
            <Heading fontSize={[150,200,200]}>404</Heading>
            <Text fontSize={[30,35,40]}>Pagina não encontrada</Text>
            <Link to={"/"} style={{textAlign:"center"}} >Voltar a pagina inicial</Link>
            
       </Stack>
       <Footer/>
    </Flex>
  )
}

export default Erro404
