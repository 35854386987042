import React from 'react'
import {Box, Button,  Heading, Text,Flex, Divider, Tag } from '@chakra-ui/react'
import { DrawerView } from '../../../../Components'
import { MdSearch } from 'react-icons/md'

function PagamentosDetalhes({data}) {

  const TIPO_TERMINAL = {
    "A": "ATM",
    "M": "Multicaixa Express",
    "L": "Internet Banking",
    "1": "Internet Banking",
  }

  return (
    <DrawerView Titulo={"Mais detalhes"} tamanho={"lg"} Botao={<Button size={["sm","md"]} leftIcon={<MdSearch/>}></Button>}>
                      <Heading mt={3}># {data.id_transaccao}</Heading>
                      <Box mt={5} fontSize={16} fontWeight={300}>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Referência</Text>
                          <Text fontWeight={700}>{data.referencia_do_servico}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Periodo</Text>
                          <Text fontWeight={700}>{data.Identificacao_Log_EGR}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Log na EGR</Text>
                          <Text fontWeight={700}>{data.numero_Log_EGR}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Transação única</Text>
                          <Text fontWeight={700}>{data.Identificacao_Log_EGR}{data.numero_Log_EGR}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Data do movimento</Text>
                          <Text fontWeight={700}>{new Date(data.data_hora_transacao_cliente).toLocaleDateString()}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Hora do movimento</Text>
                          <Text fontWeight={700}>{new Date(data.data_hora_transacao_cliente).toLocaleTimeString()}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Número do periodo</Text>
                          <Text fontWeight={700}>{data.numero_Periodo_Contabilistico}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Identificacao do terminal</Text>
                          <Text fontWeight={700}>{data.identificacao_Transacao_Local}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Terminal</Text>
                          <Text fontWeight={700}>{TIPO_TERMINAL[data.tipo_de_Terminal]}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Moeda</Text>
                          <Text fontWeight={700}>{data.codigo_de_Moeda}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Tarifa aplicada pelo banco</Text>
                          <Text fontWeight={700} color="red.300"><Tag colorScheme='red'>AKZ {Intl.NumberFormat('PT-br').format(data.tarifa_aplicada_a_operacao)}</Tag></Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Movimento</Text>
                          <Text fontWeight={700} color="green.300"><Tag colorScheme='green'>AKZ {Intl.NumberFormat('PT-br').format(data.montante_da_operacao)}</Tag></Text>
                        </Flex>
                      </Box>
                      <Box mt={5}>
                        <Box padding={10}>
                          <Divider></Divider>
                        </Box>
                        <Heading size="md">Custo á pagar pela transação</Heading>
                        <Box mt={10} fontSize={16} fontWeight={300}>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Percentual da operação</Text>
                          <Text fontWeight={700}>{data.percentagem_actual}%</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Valor minimo respeitado</Text>
                          <Text fontWeight={700}>AKZ {Intl.NumberFormat('PT-br').format(data.minimo_actual)}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Valor total á cobrar</Text>
                          <Text fontWeight={700} color="green.300"><Tag colorScheme='cyan'>AKZ {Intl.NumberFormat('PT-br').format(data.valor_total)}</Tag></Text>
                        </Flex>
                        </Box>
                      </Box>
    </DrawerView>
  )
}

export default PagamentosDetalhes
