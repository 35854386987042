import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { CodigoFinanceiro, Configuracoes, Dashboard, Erro404, EsqueciSenha, Login, Pagamentos, PagamentosPorPeriodo, PagamentosReferenciado, PeriodosContabilidade, PeriodosContabilidadePorPeriodo, RedifinirSenha, Referencias } from "../Pages";
  
const router = createBrowserRouter([
  // Autenticação
    {
      path: "/",
      element: <Login/>,
    },
    {
      path: "/esqueci-a-senha",
      element: <EsqueciSenha/>,
    },
    {
      path: "/codigo-seguranca",
      element: <CodigoFinanceiro/>,
    },
    {
      path: "/redifinir-senha",
      element: <RedifinirSenha/>,
    },
    // Gestão
    {
      path: "/dashboard",
      element: <Dashboard/>,
    },
    {
      path: "/movimentos",
      element: <Pagamentos/>,
    },
    {
      path: "/movimentos/referenciado/:referencia",
      element: <PagamentosReferenciado/>,
    },
    {
      path: "/movimentos/periodo/:periodo",
      element: <PagamentosPorPeriodo/>,
    },
    {
      path: "/Referencias",
      element: <Referencias/>,
    },
    {
      path: "/periodos",
      element: <PeriodosContabilidade/>,
    },
    {
      path: "/periodos/:periodo",
      element: <PeriodosContabilidadePorPeriodo/>,
    },
    // Configuracoes
    {
      path: "/configuracoes",
      element: <Configuracoes/>,
    },
    // Erros
    {
      path: "/*",
      element: <Erro404/>,
    },
  ]);
  
  export default router