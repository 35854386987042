
import {SessionCookie} from '../../Configurations'

const getCookie = function(){

    const loginValue = document.cookie.split(";").find(i => i.includes(SessionCookie.CLIENT)) ? document.cookie.split(";").find(i => i.includes(SessionCookie.CLIENT)).split(SessionCookie.CLIENT)[1] : "{}"

    if(loginValue === "{}") return JSON.parse(loginValue)
    else return JSON.parse(window.atob(loginValue))

}

export default getCookie;