import { Button, Flex, Heading, HStack, PinInput, PinInputField, Stack, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Footer, LogoMarca, MudarTema } from '../../Components'
import Constants from '../../Configurations/Breakpoint'


function CodigoFinanceiro() {
  const [color, setColor] = useState('')

  return (
    <Flex w="100%" h="100vh" justifyContent="center" alignItems="center" flexDirection="column">
       <MudarTema colorText={setColor}/>
       <LogoMarca/>
       <Stack spacing={4} w={Constants.breakpointsTelas}  h={400} p={10} rounded={10} color={color}>
            <form>
                <Heading textAlign="center" size="2xl" fontWeight={900}>CÓDIGO DE SEGURANÇA</Heading>
                <Text mb={10} textAlign="center" mt={2} fontWeight={200}>Enviamos um codigo de segurança em seu email</Text>
                
                <HStack alignItems="center" justifyContent="center">
                  <PinInput size="lg">
                    <PinInputField fontWeight={900}/> 
                    <PinInputField fontWeight={900}/>
                    <PinInputField fontWeight={900}/>
                    <PinInputField fontWeight={900}/>
                  </PinInput>
                </HStack>

                <Button shadow="lg" mt={5} w="100%" color="#FFF" bg='#006672' rounded={20}>Receber o código</Button>
            </form>
            <Link style={{textAlign:"center"}} to={"/"}>Voltar ao login</Link>
        </Stack>
       
        <Footer/>
    </Flex>
  )
}

export default CodigoFinanceiro
