import React, { useState } from 'react'
import {Alert, AlertIcon, Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Input, ScaleFade, Select, Text } from '@chakra-ui/react'
import { DrawerView } from '../../../../Components'
import { MdFilter, MdSearch } from 'react-icons/md'
import { useFormik } from 'formik'
import { useGetTokenLogin } from '../../../../Hooks'
import { useStoreReferencias } from '../../../../Stores'
import { RequestAPI } from '../../../../Configurations'

function ReferenciasFiltros() {
  const [textDisplay, setTextDisplay] = useState({status:"loading", text:"Aguarde o processo!"})
  const [loader, setLoader] = useState(false)
  const [entrados, setEncontrados] = useState({total: 0, total_apresentados: 0})
  const getValueLogin = useGetTokenLogin();
  const {setReferencias} = useStoreReferencias()

  const formik = useFormik({
    initialValues:{
      num_referencia:'',
      codigo_de_processamento: '80',
      montante_fixo: '',
      data_limite_pagamento: '',
      criada_r: '',
    },
    onSubmit: async (values)=> {

      try {
      
        setLoader(true)   
  
        const {data:{status, mensagem, registros}} =  await RequestAPI.post(`/referencias/pesquisar`,{...values, entidade_cliente: getValueLogin.numero_entidade})

        setTextDisplay({status:"loading", text:"Aguarde o processo!"})

        if(status === "sucesso"){

          setReferencias(mensagem)
          setEncontrados({total: registros.total, total_apresentados: registros.total_apresentados})
          setTextDisplay({status:"success", text: `Pesquisa feita com sucesso`})
          setLoader(false)
          
         }else{
           setLoader(false)
           setTextDisplay({status:"error", text: mensagem})
      
        }
      
      }catch (error) {
        console.log(error)
        setLoader(false)
        setTextDisplay({status:"error", text:"Não conseguimos ter sucesso no processo, Tente outra vez!"})
      }
      
      
    },
  })

  return (
    <DrawerView Titulo={"Pesquisar com precisão"} tamanho={"lg"} Botao={<Button bg="#006672" color="#FFFFFF" size={["sm","md"]} fontWeight={300} ml={5} leftIcon={<MdFilter/>} mb={10}>Pesquisar</Button>}>
                <form onSubmit={formik.handleSubmit}>
                  <Box>
                  <Flex direction="row" alignItems="center" justifyContent="space-between">
                    <FormControl mt={4}>
                        <FormLabel>Referência</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='text' name='num_referencia' onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </Flex>
                    </FormControl>

                    <FormControl mt={4} ml={4}>
                        <FormLabel>Montante fixo</FormLabel>
                        <Flex direction="row" alignIterems="center">
                          <Input type='number' name='montante_fixo' min={1} max={19999999999} onChange={formik.handleChange} value={formik.values.montante_fixo} />
                        </Flex>
                    </FormControl>
                  </Flex>
                  <Flex direction="row" alignItems="center" justifyContent="space-between">
                    <FormControl mt={2}>
                          <FormLabel>Estado</FormLabel>
                          <Select name='codigo_de_processamento' onChange={formik.handleChange} value={formik.values.codigo_de_processamento}>
                            <option value="80">Activada</option>
                            <option value="82">Desactivada</option>
                        </Select>
                    </FormControl>

                    <FormControl mt={2} ml={4}>
                          <FormLabel>Limite de pagamento</FormLabel>
                          <Input type='date' name='data_limite_pagamento' onChange={formik.handleChange} value={formik.values.data_limite_pagamento} />
                      </FormControl>

                      <FormControl mt={2} ml={4}>
                          <FormLabel>Data de criação</FormLabel>
                          <Input type='date' name='criada_r' onChange={formik.handleChange} value={formik.values.criada_r} />
                      </FormControl>
                  </Flex> 
                  
                    <Button leftIcon={<MdSearch/>} bg="#006672" color="#FFFFFF" isDisabled={loader}  type='submit' mt={5}>Pesquisar</Button>

                    {loader && <ScaleFade initialScale={0.9} in={loader} transition={{ exit: { delay: 1 }, enter: { duration: 0.5 } }}>
                      <Alert mt={10} p={[3]} status={textDisplay.status}  width={["xs","lg","xl"]} variant='left-accent'>
                        <AlertIcon />
                        {textDisplay.text}
                      </Alert>
                    </ScaleFade>}
                 
                </Box> 
              </form> 
              <Box mt={0}>
                  <Box padding={10}>
                    <Divider></Divider>
                  </Box>
                  <Heading size="md">Dados pesquisados</Heading>
                  <Box mt={10} fontSize={16} fontWeight={300}>
                    <Flex mb={5} justifyContent="space-between">
                      <Text>Encontrados</Text>
                      <Text fontWeight={700}>{entrados.total}</Text>
                    </Flex>
                    <Flex mb={5} justifyContent="space-between">
                      <Text>Tragos</Text>
                      <Text fontWeight={700}>{entrados.total_apresentados}</Text>
                    </Flex>
                  </Box>
              </Box>
                      
    </DrawerView>
  )
}

export default ReferenciasFiltros
