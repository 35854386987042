import React from 'react'
import { Image } from '@chakra-ui/react'
import Logo from '../../Assets/Images/logo.png'

function LogoMarca() {
  return (
    <div>
      <Image src={Logo} alt='LOGO' sizes='sm' w={150} mb={5}/>
    </div>
  )
}

export default LogoMarca
