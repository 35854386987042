import { Alert, AlertIcon, Box, Button, Center, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { FaMoneyCheckAlt } from 'react-icons/fa'
import { GiCardExchange } from 'react-icons/gi'
import { RequestAPI } from '../../../../Configurations';
import { useGetTokenLogin } from '../../../../Hooks';
import { SkeletonLoader } from '../../../../Components';
import useSWR from 'swr';

function ReferenciasContador() {

  const getValueLogin = useGetTokenLogin();

  const fetcher = async (url) =>{
    const {data} = await RequestAPI.get(url)

    return data
  }

  const { data, error, isLoading } = useSWR(`/referencias/entidade/${getValueLogin.numero_entidade}`, fetcher)

  return (
    error ? <Center>
    <Alert p={[5]} status={"error"}  width={["xs","lg","3xl"]} variant='left-accent'>
    <AlertIcon />
       Não conseguimos carregar suas informações, por favor recarregue a pagina!
    </Alert>
    </Center>
    : isLoading ? <SkeletonLoader/> :
    <Flex ml={[0,10]} direction="column" alignSelf="flex-start" mt={[5,20]} justifySelf="center" w={["100%","auto"]} p={[5,0]}>
        <Box rounded={5} p={3}>
                <Text p={2} fontSize={[17,16]} rounded={5} fontWeight={300} size="sm">Referências geradas</Text>
                <Heading p={2} py={3} display="flex" size={["2xl","lg"]} justifyContent="space-between" alignItems="center"><GiCardExchange size={50}/> {data.registros.total}</Heading>
                <Button size={["sm","sm","xs"]} p={3}>Visualizar todas</Button>
        </Box>
        <Box mt={10} rounded={5} p={3}>
                <Text p={2} fontSize={[17,16]}  rounded={5} fontWeight={300} size="sm">Referências usadas</Text>
                <Heading p={2} py={3} display="flex" size={["2xl","lg"]} justifyContent="space-between" alignItems="center"><FaMoneyCheckAlt size={50}/> {data.registros.total_uadas}</Heading>
                <Button size={["sm","sm","xs"]} p={3}>Visualizar todas</Button>
        </Box>
    </Flex>
  )
}

export default ReferenciasContador
