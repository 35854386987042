import { Button, Flex, Heading, Input, Stack, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdPassword } from 'react-icons/md'
import { Footer, LogoMarca, MudarTema } from '../../Components'
import Constants from '../../Configurations/Breakpoint'


function RedifinirSenha() {
  const [color, setColor] = useState('')

  return (
    <Flex w="100%" h="100vh" justifyContent="center" alignItems="center" flexDirection="column">
       <MudarTema colorText={setColor}/>
       <LogoMarca/>
       <Stack spacing={4} w={Constants.breakpointsTelas} h={400} p={10} rounded={10} color={color}>
            <form>
                <Heading textAlign="center" size="2xl" fontWeight={900}>REDIFINIR SENHA NOVA</Heading>
                <Text mb={10} textAlign="center" fontWeight={200}>Defina uma senha nova para o seu acesso</Text>
                
                <Flex justifyContent="space-between" alignItems="center">
                    <MdPassword size={40} color='#006672'/>
                    <Input type='password' fontWeight={900} placeholder='Introduza sua senha nova' p={3} rounded={20} ml={5} border="3px solid #006672" />
                </Flex>

                <Flex justifyContent="space-between" alignItems="center" mt={5}>
                    <MdPassword size={40} color='#006672'/>
                    <Input type='password' fontWeight={900} placeholder='Repita sua senha nova' p={3} rounded={20} ml={5} border="3px solid #006672" />
                </Flex>

                <Button shadow="lg" mt={5} w="100%" color="#FFF" bg='#006672' rounded={20}>Mudar a senha</Button>
            </form>
        </Stack>
       
        <Footer/>
    </Flex>
  )
}

export default RedifinirSenha
