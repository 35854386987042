import React, { useState }from 'react'
import {Alert, AlertIcon, Box, Button, Divider, Flex, FormControl, ScaleFade, FormLabel, Heading, Input, Text } from '@chakra-ui/react'
import { DrawerView } from '../../../../Components'
import { MdFilter, MdSearch } from 'react-icons/md'
import { useFormik } from 'formik'
import { RequestAPI } from '../../../../Configurations'
import { useGetTokenLogin } from '../../../../Hooks'
import { useStorePeriodos } from '../../../../Stores'

function PeriodosFiltros() {

  const [textDisplay, setTextDisplay] = useState({status:"loading", text:"Aguarde o processo!"})
  const [loader, setLoader] = useState(false)
  const [entrados, setEncontrados] = useState({total: 0, total_apresentados: 0})
  const getValueLogin = useGetTokenLogin();
  const {setPeriodos} = useStorePeriodos()

  const formik = useFormik({
    initialValues:{
      Identificacao_Log_EGR: '',
      somatorio: '',
      data_periodo: '',
    },
    onSubmit: async (values)=> {
      
      try {
      
        setLoader(true)   
  
        const {data:{status, mensagem, registros}} =  await RequestAPI.post(`/pagamentos/pesquisar/periodos`,{...values, entidade_cliente: getValueLogin.numero_entidade})

        setTextDisplay({status:"loading", text:"Aguarde o processo!"})
        
        if(status === "sucesso"){

          setPeriodos(mensagem)
          setEncontrados({total: registros.total, total_apresentados: registros.total_apresentados})
          setTextDisplay({status:"success", text: `Pesquisa feita com sucesso`})
          setLoader(false)
          
         }else{
           setLoader(false)
           setTextDisplay({status:"error", text: mensagem})
      
        }
      
      }catch (error) {
        console.log(error)
        setLoader(false)
        setTextDisplay({status:"error", text:"Não conseguimos ter sucesso no processo, Tente outra vez!"})
      }

    },
  })

  return (
    <DrawerView Titulo={"Pesquisar com precisão"} tamanho={"lg"} Botao={<Button bg="#006672" color="#FFFFFF" size={["sm","md"]} fontWeight={300} ml={5} leftIcon={<MdFilter/>} mb={10}>Pesquisar</Button>}>
                      <form onSubmit={formik.handleSubmit}>
                  <Box>
                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                      <FormControl mt={4}>
                        <FormLabel>Valor</FormLabel>
                        <Flex direction="row" alignIterems="center">
                          <Input type='number' name='somatorio' min={10} max={19999999999}
                            onChange={formik.handleChange}
                            value={formik.values.somatorio} />
                        </Flex>
                      </FormControl>
                    </Flex>
                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                     <FormControl mt={4}>
                        <FormLabel>Data atuante</FormLabel>
                        <Input type='date' name='data_periodo'  
                          onChange={formik.handleChange}
                          value={formik.values.data_periodo} />
                      </FormControl>
                      <FormControl mt={4} ml={4}>
                        <FormLabel>Periodo</FormLabel>
                        <Input type='text' name='Identificacao_Log_EGR'  
                          onChange={formik.handleChange}
                          value={formik.values.Identificacao_Log_EGR} />
                      </FormControl>
                    </Flex>
                    <Button leftIcon={<MdSearch/>} bg="#006672" isDisabled={loader} color="#FFFFFF"  type='submit' mt={5}>Pesquisar</Button>
                 
                </Box> 
              </form> 

              {loader && <ScaleFade initialScale={0.9} in={loader} transition={{ exit: { delay: 1 }, enter: { duration: 0.5 } }}>
                <Alert mt={10} p={[3]} status={textDisplay.status}  width={["xs","lg","xl"]} variant='left-accent'>
                  <AlertIcon />
                  {textDisplay.text}
                </Alert>
              </ScaleFade>}

              <Box mt={5}>
                        <Box padding={10}>
                          <Divider></Divider>
                        </Box>
                        <Heading size="md">Dados pesquisados</Heading>
                        <Box mt={10} fontSize={16} fontWeight={300}>
                          <Flex mb={5} justifyContent="space-between">
                            <Text>Encontrados</Text>
                            <Text fontWeight={700}>{entrados.total}</Text>
                          </Flex>
                          <Flex mb={5} justifyContent="space-between">
                            <Text>Tragos</Text>
                            <Text fontWeight={700}>{entrados.total_apresentados}</Text>
                          </Flex>
                        </Box>
              </Box>
                      
    </DrawerView>
  )
}

export default PeriodosFiltros
