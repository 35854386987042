import React from 'react'
import {PagamentosDetalhes} from '../';
import { Alert, AlertIcon, Center, Show, Table, Tag, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { useGetTokenLogin } from '../../../../Hooks';
import { RequestAPI } from '../../../../Configurations';
import { SkeletonLoader } from '../../../../Components';
import { useStoreTransacoes } from '../../../../Stores';

function PagamentosLista({filtro}) { 
  
  const TIPO_TERMINAL = {
    "A": "ATM",
    "M": "Multicaixa Express",
    "L": "Internet Banking",
    "1": "Internet Banking",
  }

  const getValueLogin = useGetTokenLogin();
  const {transacoes} = useStoreTransacoes()
  

  const fetcher = async (url) =>{
    const {data} = await RequestAPI.get(url)

    return data
  }

  const url = filtro ? `/transacoes/entidade/${getValueLogin.numero_entidade}/${filtro}` : `/transacoes/entidade/${getValueLogin.numero_entidade}`
  const { data, error, isLoading } = useSWR(url, fetcher)

  const result = transacoes.length ? transacoes : data?.mensagem

  return (
    error ? <Center>
      <Alert p={[5]} status={"error"}  width={["xs","lg","3xl"]} variant='left-accent'>
      <AlertIcon />
      Não conseguimos carregar suas informações, por favor recarregue a pagina!
      </Alert>
      </Center>
      : isLoading ? <SkeletonLoader/> :
    <Table variant='simple' fontSize={[12,14,16]}>
              <Thead>
                <Tr>
                  <Th fontSize={[12,14,16]}>Referência</Th>
                  <Th fontSize={[12,14,16]}>Valor</Th>
                  <Show above='sm'>
                  <Th fontSize={[12,14,16]}>Tarifa aplicada</Th>
                  <Th fontSize={[12,14,16]}>Terminal</Th>
                  <Th fontSize={[12,14,16]}>Periodo</Th>
                  <Th fontSize={[12,14,16]}>Transação</Th>
                  <Th fontSize={[12,14,16]}>Tempo</Th>
                  </Show>
                  <Th fontSize={[12,14,16]}>Mais</Th>
                </Tr>
              </Thead>
              <Tbody>
                {result.map((value,i) => <Tr key={i}>
                  <Td _hover={{color:"#006672"}}><Link to={`/movimentos/referenciado/${value.referencia_do_servico}`}>{value.referencia_do_servico}</Link></Td>
                  <Td fontWeight={400}><Tag colorScheme='green'>AKZ {Intl.NumberFormat("PT-br").format(value.montante_da_operacao)}</Tag></Td>
                  <Show above='sm'>
                  <Td fontWeight={400}><Tag colorScheme='gray'>AKZ {Intl.NumberFormat("PT-br").format(value.tarifa_aplicada_a_operacao)}</Tag></Td>
                  <Td>{TIPO_TERMINAL[value.tipo_de_Terminal]}</Td>
                  <Td _hover={{color:"#006672"}}><Link to={`/movimentos/periodo/${value.Identificacao_Log_EGR}`}>{value.Identificacao_Log_EGR}</Link></Td>
                  <Td>{value.Identificacao_Log_EGR}{value.numero_Log_EGR}</Td>
                  <Td>{new Date(value.data_hora_transacao_cliente).toLocaleDateString()} {new Date(value.data_hora_transacao_cliente).toLocaleTimeString()}</Td>
                  </Show>
                  <Td>
                    <PagamentosDetalhes data={value}/>
                  </Td>
                </Tr>
              )}
              </Tbody>
            </Table>
  )
}

export default PagamentosLista
