import React from 'react'
import { Button, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { MdDarkMode, MdLightMode } from "react-icons/md";


function LogoMarca({colorText, bgColor = ()=>{}}) {

  const { colorMode, toggleColorMode } = useColorMode()
  const color = useColorModeValue('#006672', '#006672')
  const bg = useColorModeValue('#FFF', '#011627')
  
  bgColor(bg)

  const changeColors = () => {

    bgColor(bg)
    colorText(color)
    toggleColorMode()
    // #DEEAFF
  }

  return (
    <div>
      <Button onClick={changeColors}  mr={10} color={color} bg="transparent">
        {colorMode === 'light' ? <MdDarkMode size={20}/> : <MdLightMode size={20}/>}
      </Button>
    </div>
  )
}

export default LogoMarca
