import {Box, Center, Heading, TableContainer, Text, Flex, Show, Hide} from '@chakra-ui/react'
import React from 'react'
import {Footer, Header} from '../../Components';
import {ReferenciasContador, ReferenciasFiltros, ReferenciasGerar, ReferenciasLista, ReferenciasPaginacao, ReferenciasRelatorios} from './Complementos';



function Refencias() {
  

  return (
    <Box>
        <Header pagina="Dashboard"/> 
        
        <Center mt={10} display="flex" textAlign="center" flexDir="column">
          <Heading size={["2xl","3xl"]} mb={3} textTransform="uppercase">Referências</Heading>
          <Text>Gerencias suas referências para que seus clientes possam fazer pagamentos!</Text>
        </Center>
        <Center my={20} display="flex" flexDirection={["column","row"]}>
          <TableContainer>
          <Show above='sm'>
            <Flex justifyContent="space-between">
              <ReferenciasPaginacao/>
              <Flex>
                <ReferenciasFiltros/>
                <ReferenciasRelatorios/>
                <ReferenciasGerar/>
              </Flex>
            </Flex>
            </Show>
            <Hide above='sm'>
              <ReferenciasGerar margin='0' top='10'/>
            </Hide>
            <ReferenciasLista/>
          </TableContainer>
          <ReferenciasContador/>
        </Center>
        <Footer/>
    </Box>
  )
}

export default Refencias