
import {SessionCookie} from '../../Configurations'

const saveCookie = function(data, dias){

    var now = new Date();
    var time = now.getTime();
    var expireTime = time + (1000*60*60*24*(Number(dias)));
    now.setTime(expireTime);
    var responseData = window.btoa(JSON.stringify(data))
    var cookie = SessionCookie.CLIENT+responseData+';expires='+now.toGMTString()+';path=/';
    document.cookie = cookie

    


}

export default saveCookie;