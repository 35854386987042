import {Box, Center, TableContainer, Flex, Show } from '@chakra-ui/react'
import React from 'react'
import {Footer, Header, TituloPagina} from '../../Components';
import {PeriodosPaginacao, PeriodosRelatorios, PeriodosFiltros, PeriodosLista} from './Complementos';




function Periodos() {
  
  return (
    <Box>
        <Header pagina="Dashboard"/> 
        <TituloPagina titulo="Periodos Contabilisticos" descricao="Seus pagamentos são organizados e agrupados em periodos, para que possas fazer um balanço diario!"/>
        <Center my={20} display="flex" justifyContent="space-evenly" alignItems="center" flexDirection={["column","row"]}>
        <TableContainer>
        <Show above='sm'>
          <Flex justifyContent="space-between">
            <PeriodosPaginacao/>
            <Flex>
              <PeriodosFiltros/>
              <PeriodosRelatorios/>
            </Flex>
          </Flex>
          </Show>
          <PeriodosLista/>
          </TableContainer>

        </Center>

        <Footer/>
    </Box>
  )
}

export default Periodos
