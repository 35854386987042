import {Box, Center, TableContainer, Text, Flex, Show, Tag } from '@chakra-ui/react'
import React from 'react'
import {Footer, Header, TituloPagina} from '../../Components';
import {PagamentosFiltros, PagamentosLista, PagamentosPaginacao, PagamentosRelatorios} from './Complementos';
import { useParams } from 'react-router-dom';



function PagamentosPorPeriodo() {
 
  const {periodo} = useParams();

  return (
    <Box>
        <Header pagina="Dashboard"/> 
        <TituloPagina titulo="Transações" descricao="Monitore em tempo real seus pagamentos chegando até voçê!"/>
        <Center mt={10} display="flex" textAlign="center" flexDir="column">
          <Text fontSize={20}><Tag colorScheme='yellow'>({periodo})</Tag></Text>
        </Center>
        <Center my={20} display="flex" justifyContent="space-evenly" alignItems="center" flexDirection={["column","row"]}>
        <TableContainer>
        <Show above='sm'>
          <Flex justifyContent="space-between">
            <PagamentosPaginacao/>
            <Flex>
              <PagamentosFiltros/>
              <PagamentosRelatorios/>
            </Flex>
          </Flex>
          </Show>
            <PagamentosLista filtro={`periodo/${periodo}`}/>
          </TableContainer>

        </Center>

        <Footer/>
    </Box>
  )
}

export default PagamentosPorPeriodo
