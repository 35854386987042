import React from 'react'
import {Box, Button, Flex, FormControl, FormLabel, Heading, Input, Select } from '@chakra-ui/react'
import { MdOutlineMailOutline, MdSave } from 'react-icons/md';
import { useFormik } from 'formik';

function ConfiguracoesSMTP() {

    const formik = useFormik({
        initialValues:{
          num_referencia:'',
            data_limite_pagamento:'',
            estado_atm:'Activo',
            montante_fixo:''
        },
        onSubmit: async (values)=> {
    
        },
      })

  return (
    <Box mt={10} px={[10]} w={["100%","40%","70%"]}>
                <Heading display="flex" alignItems="center" fontSize={20}><MdOutlineMailOutline size={30} /> &nbsp;&nbsp;&nbsp;&nbsp;Configurações de e-mail SMTP</Heading>  
                <form onSubmit={formik.handleSubmit}>
                    <Box alignItems="center" display="flex" flexDirection="column">
                      <Flex w={["100%","60%","70%"]} mt={[5,5]} flexDirection={["column","row"]}>
                        <FormControl mt={4} >
                              <FormLabel>Host SMTP</FormLabel>
                              <Input type='text' flex={1}  name='num_referencia'  required onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                        <FormControl mt={4} ml={[0,4]}>
                              <FormLabel>USUÁRIO SMTP</FormLabel>
                              <Input type='text' flex={1}  name='num_referencia'  required onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                      </Flex>
                      <Flex w={["100%","60%","70%"]} mt={[5,5]} flexDirection={["column","row"]}>
                        <FormControl mt={4} >
                              <FormLabel>SENHA SMTP</FormLabel>
                              <Input type='text' flex={1}  name='num_referencia'  required onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                        <FormControl mt={4} ml={[0,4]}>
                              <FormLabel>PORTA SMTP</FormLabel>
                              <Input type='text' flex={1}  name='num_referencia'  required onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                      </Flex>
                      <Flex w={["100%","60%","70%"]} mt={[5,5]} flexDirection={["column","row"]}>
                        <FormControl mt={4} >
                              <FormLabel>Segurança SMTP</FormLabel>
                              <Select  name='codigo_de_processamento' onChange={formik.handleChange} value={formik.values.codigo_de_processamento}>
                                  <option value="None">Nenhum</option>
                                  <option value="SSL">SSL</option>
                                  <option value="TLS">TLS</option>
                              </Select>
                        </FormControl>
                        <FormControl mt={4} ml={[0,4]}>
                              <FormLabel>Domínio de autenticação SMTP</FormLabel>
                              <Input type='text' flex={1}  name='num_referencia'  required onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                      </Flex>
                      
                      <Button leftIcon={<MdSave/>} bg="#006672" color="#FFFFFF"  type='submit' mt={[5,5]}>Salvar</Button>
                  
                  </Box> 
                </form>                 
                
             </Box>
  )
}

export default ConfiguracoesSMTP
