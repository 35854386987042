import React from 'react'
import {Box, Button,  Heading, Text,Flex, Tag } from '@chakra-ui/react'
import { DrawerView } from '../../../../Components'
import { MdSearch } from 'react-icons/md'

function ReferenciasDetalhes({data}) {
  return (
    <DrawerView Titulo={"Mais detalhes"} tamanho={"lg"} Botao={<Button size={["sm","md"]} leftIcon={<MdSearch/>}></Button>}>
                      <Heading mt={3}># {data.num_referencia}</Heading>
                      <Box mt={5} fontSize={16} fontWeight={300}>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Estado</Text>
                          <Text fontWeight={700}><Tag colorScheme={data.estado_atm === "Activo" ? "green" : data.estado_atm === "Inactivo" ? "red": "yellow"}>{data.estado_atm}</Tag></Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Produto</Text>
                          <Text fontWeight={700}>{data.produto}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Entidade</Text>
                          <Text fontWeight={700}>{data.entidade_cliente}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Limite de pagamento</Text>
                          <Text fontWeight={700}>{new Date(data.data_limite_pagamento).toLocaleDateString()}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Gerado em</Text>
                          <Text fontWeight={700}>{new Date(data.criada_r).toLocaleDateString()} {new Date(data.criada_r).toLocaleTimeString()}</Text>
                        </Flex>
                        {data.aceitar_fixo === "SIM" && <>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Valor fixo</Text>
                          <Text fontWeight={700} color="green.300">{data.aceitar_fixo}</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Montante á pagar</Text>
                          <Text fontWeight={700} color="green.300">{Intl.NumberFormat('PT-br').format(data.montante_minimo)}</Text>
                        </Flex>
                        </>}
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Usado</Text>
                          <Text fontWeight={700}>{data.usabilidade === "true" ? "SIM" : "NÃO"}</Text>
                        </Flex>
                      </Box>
                      
    </DrawerView>
  )
}

export default ReferenciasDetalhes
