import React from 'react'
import {Alert, AlertIcon,Center,Show, Table, TableCaption, TableContainer, Tag, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react'
import useSWR from 'swr'
import { useGetTokenLogin } from '../../../../Hooks'
import { RequestAPI } from '../../../../Configurations'
import { SkeletonLoader } from '../../../../Components'
import { Link } from 'react-router-dom'
import PeriodosDetalhes from '../Periodos/PeriodosDetalhes';

function DashboardPeridos() {

  const getValueLogin = useGetTokenLogin();

  const fetcher = async (url) =>{
    const {data} = await RequestAPI.get(url)

    return data
  }

  const { data, error, isLoading } = useSWR(`/pagamentos/entidade/${getValueLogin.numero_entidade}/periodo/contabilistico/`, fetcher)
  return (

    error ? <Center>
      <Alert p={[5]} status={"error"}  width={["xs","lg","3xl"]} variant='left-accent'>
      <AlertIcon />
      Não conseguimos carregar suas informações, por favor recarregue a pagina!
      </Alert>
      </Center>
      : isLoading ? <SkeletonLoader/> :
    <TableContainer>
  <Table variant='simple'>
    <TableCaption fontSize={[25,30]}>Recentes Periodos</TableCaption>
    <Thead>
    <Tr>
                  <Th fontSize={[12,14,16]}>Movimentos</Th>
                  <Th fontSize={[12,14,16]}>Montante</Th>
                  <Show above='sm'>
                  <Th fontSize={[12,14,16]}>Periodo</Th>
                  <Th fontSize={[12,14,16]}>Tempo</Th>
                  </Show>
                  <Th fontSize={[12,14,16]}>Mais</Th>
                </Tr>
    </Thead>
    <Tbody>
    {data.mensagem.map((value,i) => <Tr key={i}>
                  <Td>{value.quantidade}</Td>
                  <Td fontWeight={400}><Tag colorScheme='green'>AKZ {Intl.NumberFormat("PT-br").format(value.somatorio)}</Tag></Td>
                  <Show above='sm'>
                  <Td _hover={{color:"#006672"}}><Link to={`/movimentos/periodo/${value.Identificacao_Log_EGR}`}>{value.Identificacao_Log_EGR}</Link></Td>
                  <Td>{new Date(value.data_periodo).toLocaleDateString()} ~ 20h</Td>
                  </Show>
                  <Td>
                    <PeriodosDetalhes data={value}/>
                  </Td>
                </Tr>
              ).slice(0,5)}
    </Tbody>
  </Table>
</TableContainer>
  )
}

export default DashboardPeridos
