import React from 'react'
import {Alert, AlertIcon, Box, Button, Flex, FormControl, FormLabel, Heading, Input, Select } from '@chakra-ui/react'
import { DrawerView } from '../../../../Components'
import { MdEdit, MdSave } from 'react-icons/md'
import { useFormik } from 'formik'

function ReferenciasEditar({data}) {

  const formik = useFormik({
    initialValues:{
      num_referencia:'',
        data_limite_pagamento:'',
        estado_atm:'Activo',
        dia_inicio:'',
        dia_final:''
    },
    onSubmit: async (values)=> {
 
      
    },
  })

  return (
    <DrawerView Titulo={"Editar a referencia"} tamanho={"lg"} Botao={<Button bg="green.400" color="#FFF" size={["sm","md"]} leftIcon={<MdEdit/>}></Button>}>
                    <Heading my={3}># {data.num_referencia}</Heading>
                      <form onSubmit={formik.handleSubmit}>
                    <Box>
                      
                      <Flex>
                          <FormControl mt={4}>
                              <FormLabel>Limite de pagamento</FormLabel>
                                <Input type='date'  name='data_limite_pagamento' required onChange={formik.handleChange} value={formik.values.data_limite_pagamento} />
                          </FormControl>
                          
                          <FormControl mt={4} ml={4}>
                            <FormLabel>Valor á pagar</FormLabel>
                            <Input type='number'  name='montante_fixo' onChange={formik.handleChange} value={formik.values.montante_fixo} />                              
                          </FormControl>
                      </Flex>
                      <Flex>
                          <FormControl mt={2}>
                                <FormLabel>Estado</FormLabel>
                                <Select required name='estado_atm' onChange={formik.handleChange} value={formik.values.estado_atm}>
                                  <option value="80">Activo</option>
                                  <option value="82">Inactivo</option>
                                  <option value="82">Á Processar</option>
                              </Select>
                          </FormControl>
                      </Flex>
                      
                      <Button leftIcon={<MdSave/>} bg="#006672" color="#FFFFFF"  type='submit' mt={5}>Actualizar</Button>

                      <Alert mt={10} status='success' variant='left-accent'>
                        <AlertIcon />
                        Referência actualizada com sucesso!
                      </Alert>
                  
                  </Box> 
                    </form>
                      
    </DrawerView>
  )
}

export default ReferenciasEditar
