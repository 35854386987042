import {Box, Center, TableContainer, Flex, Show, Tag, Text, Card, CardHeader, CardBody, Heading } from '@chakra-ui/react'
import React, { useState } from 'react'
import {Footer, Header, TituloPagina} from '../../Components';
import {PeriodosPaginacao, PeriodosListaPorPeriodo} from './Complementos';
import { useParams } from 'react-router-dom';




function Periodos() {

  const [data, setData] = useState(null)

  const {periodo} = useParams();
  
  return (
    <Box>
        <Header pagina="Dashboard"/> 
        <TituloPagina titulo="Periodos Contabilisticos" descricao="Seus pagamentos são organizados e agrupados em periodos, para que possas fazer um balanço diario!"/>
        <Center mt={10} display="flex" textAlign="center" flexDir="column">
          <Text fontSize={20}><Tag colorScheme='yellow'>({periodo})</Tag></Text>
        </Center>
        <Center my={[10,20]} display="flex" alignItems="center" flexDirection={["column","row"]}>
        <Box alignSelf={["auto","flex-start"]} mr={[0,10]}>
          <Card>
            <CardHeader>
              <Heading size='md'>Dados das transações</Heading>
            </CardHeader>
            <CardBody>
              <Flex mb={5} justifyContent="space-between">
                <Text>Volume transacionado</Text>
                <Text fontWeight={700} color="green.300"><Tag colorScheme='green'>AKZ {Intl.NumberFormat("PT-br").format(data?.montante?.somatorio)}</Tag></Text>
              </Flex>
              <Flex mb={5} justifyContent="space-between">
                <Text>Quantidade</Text>
                <Text fontWeight={700}>{data?.pagamentosQuantidade}</Text>
              </Flex>
            </CardBody>
          </Card>
          <Card mt={[5,10]}>
            <CardHeader>
              <Heading size='md'>Dados das referências envolvidas</Heading>
            </CardHeader>
            <CardBody>
              <Flex mb={5} justifyContent="space-between">
                <Text>Usadas</Text>
                <Text fontWeight={700}>{data?.referencias.length}</Text>
              </Flex>
            </CardBody>
          </Card>
        </Box>
        <TableContainer mt={[10,0]}>
        <Show above='sm'>
          <Flex justifyContent="space-between">
            <PeriodosPaginacao/>
          </Flex>
        </Show>
        <PeriodosListaPorPeriodo filtro={periodo} detalhes={setData}/>
        </TableContainer>

        </Center>

        <Footer/>
    </Box>
  )
}

export default Periodos
