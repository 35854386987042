import React from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure
  } from '@chakra-ui/react'

function DrawerView({Botao, Titulo, tamanho, children, lado}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <div>
      <div onClick={onOpen}>
        {Botao}
      </div>
      <Drawer placement={lado ? lado :"right"} onClose={onClose} isOpen={isOpen} size={tamanho}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color="#FFFFFF" />
          <DrawerHeader bg="#006672" color="#FFFFFF" borderBottomWidth='1px'>{Titulo}</DrawerHeader>
          <DrawerBody>
            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  )
}

export default DrawerView
