import React from 'react'
import {Alert, AlertIcon, Center, Show, Table, TableCaption, TableContainer, Tag, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react'
import useSWR from 'swr'
import { useGetTokenLogin } from '../../../../Hooks'
import { RequestAPI } from '../../../../Configurations'
import { SkeletonLoader } from '../../../../Components'
import { Link } from 'react-router-dom'
import PagamentosDetalhes from '../Pagamentos/PagamentosDetalhes'

function DashboardTransacoes() {

  const getValueLogin = useGetTokenLogin();

  const fetcher = async (url) =>{
    const {data} = await RequestAPI.get(url)

    return data
  }

  const { data, error, isLoading } = useSWR(`/transacoes/entidade/${getValueLogin.numero_entidade}`, fetcher)
  

  return (
    error ? <Center>
      <Alert p={[5]} status={"error"}  width={["xs","lg","3xl"]} variant='left-accent'>
      <AlertIcon />
      Não conseguimos carregar suas informações, por favor recarregue a pagina!
      </Alert>
      </Center>
      : isLoading ? <SkeletonLoader/> :
<TableContainer>
  <Table variant='simple'>
    <TableCaption fontSize={[25,30]}>Recentes Pagamentos</TableCaption>
    <Thead>
                <Tr>
                  <Th fontSize={[12,14,16]}>Referência</Th>
                  <Th fontSize={[12,14,16]}>Valor</Th>
                  <Show above='sm'>
                  <Th fontSize={[12,14,16]}>Transação</Th>
                  <Th fontSize={[12,14,16]}>Tempo</Th>
                  </Show>
                  <Th fontSize={[12,14,16]}>Mais</Th>
                </Tr>
              </Thead>
    <Tbody>

    {data.mensagem.map((value,i) => <Tr key={i}>
                  <Td _hover={{color:"#006672"}}><Link to={`/movimentos/referenciado/${value.referencia_do_servico}`}>{value.referencia_do_servico}</Link></Td>
                  <Td fontWeight={400}><Tag colorScheme='green'>AKZ {Intl.NumberFormat("PT-br").format(value.montante_da_operacao)}</Tag></Td>
                  <Show above='sm'>
                  <Td>{value.Identificacao_Log_EGR + value.numero_Log_EGR}</Td>
                  <Td>{new Date(value.data_movimento || value.data_do_processamento + " " + value.hora_do_movimento || value.data_do_processamento).toLocaleDateString()} {new Date(value.data_hora_transacao_cliente).toLocaleTimeString()}</Td>
                  </Show>
                  <Td>
                    <PagamentosDetalhes data={value}/>
                  </Td>
                </Tr>
              ).slice(0,5)}
    </Tbody>
  </Table>
</TableContainer>
  )
}

export default DashboardTransacoes
