import React from 'react'
import {Box, Button,  Heading, Text,Flex, Divider, Tag } from '@chakra-ui/react'
import { DrawerView } from '../../../../Components'
import { MdSearch } from 'react-icons/md'
import { Link } from 'react-router-dom'

function PeriodosDetalhes({data}) {
  return (
    <DrawerView Titulo={"Mais detalhes"} tamanho={"lg"} Botao={<Button size={["sm","md"]} leftIcon={<MdSearch/>}></Button>}>
                      <Heading mt={3}># {data.Identificacao_Log_EGR ? data.Identificacao_Log_EGR : data.referencia_do_servico}</Heading>
                      <Box mt={5} fontSize={16} fontWeight={300}>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Quantidade de transações</Text>
                          <Text fontWeight={700}>{data.quantidade}</Text>
                        </Flex>
                        {data.data_periodo && <Flex mb={5} justifyContent="space-between">
                          <Text>Referências usadas</Text>
                          <Text fontWeight={700}>{data?.registro?.referencias || <Button as={Link} to={`/periodos/${data.Identificacao_Log_EGR}`} size="sm" colorScheme='teal'>Ver</Button>}</Text>
                        </Flex>}
                        {data.data_periodo && <Flex mb={5} justifyContent="space-between">
                          <Text>Data até 20h</Text>
                          <Text fontWeight={700}>{data.data_periodo ? new Date(data.data_periodo).toLocaleDateString() : new Date(data.data_do_movimento).toLocaleDateString()}</Text>
                        </Flex>}
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Movimento</Text>
                          <Text fontWeight={700} color="green.300"><Tag colorScheme='green'>AKZ {Intl.NumberFormat("PT-br").format(data.somatorio)}</Tag></Text>
                        </Flex>
                      </Box>
                      <Box mt={5}>
                        <Box padding={10}>
                          <Divider></Divider>
                        </Box>
                        <Heading size="md">Custo á pagar pela transação</Heading>
                        <Box mt={10} fontSize={16} fontWeight={300}>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Percentual da operação</Text>
                          <Text fontWeight={700}>0,10%</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Valor minimo respeitado</Text>
                          <Text fontWeight={700}>AKZ 30</Text>
                        </Flex>
                        <Flex mb={5} justifyContent="space-between">
                          <Text>Valor total á cobrar</Text>
                          <Text fontWeight={700} color="green.300"><Tag colorScheme='cyan'>AKZ {Intl.NumberFormat("PT-br").format(data.somatorio * 0.001)}</Tag></Text>
                        </Flex>
                        </Box>
                      </Box>
    </DrawerView>
  )
}

export default PeriodosDetalhes
