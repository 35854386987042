import { Center, Heading, Text } from '@chakra-ui/react'
import React from 'react'

function TituloPagina({titulo, descricao}) {
  return (
    <Center mt={10} display="flex" textAlign="center" flexDir="column">
        <Heading size={["2xl","3xl"]} mb={3} textTransform="uppercase">{titulo}</Heading>
        <Text>{descricao}</Text>
    </Center>
  )
}

export default TituloPagina
