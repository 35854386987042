import React from 'react'
import { Image } from '@chakra-ui/react'
import Logo from '../../Assets/Images/logoWhite.png'

function LogoMarcaWhite() {
  return (
    <div>
      <Image src={Logo} w={[30,10,10]}/>
    </div>
  )
}

export default LogoMarcaWhite
