import {Box, Center} from '@chakra-ui/react'
import React from 'react'
import {Footer, Header, TituloPagina} from '../../Components';
import { DashboardGrafico, DashboardPeridos, DashboardTransacoes } from './Complementos';



function Dashboard() {
   

  return ( 
    <Box>
        <Header/>
        <TituloPagina titulo="Dashboard" descricao="Tenha uma visão geral e simplificada do que acontece em suas finanças"/>
        <DashboardGrafico/>
        <Center mt={20} display="flex" justifyContent="space-evenly" alignItems="center" flexDirection={["column","row"]}>
          <DashboardTransacoes/>
          <DashboardPeridos/>
        </Center>

        <Footer/>
    </Box>
  )
}

export default Dashboard
