import React from 'react'
import {Box, Button,  Heading, Text,Flex, Center, Alert, AlertIcon } from '@chakra-ui/react'
import { DrawerView } from '../../../../Components'
import { MdDelete } from 'react-icons/md'

function ReferenciasExcluir({data}) {
  return (
    <DrawerView Titulo={"Exclusão de referencias"} tamanho={"lg"} Botao={<Button size={["sm","md"]} bg="red.400" color="#FFF" leftIcon={<MdDelete/>}></Button>}>

                    <Center flexDir="column" w="100%">
                      <Heading mt={10}># {data.num_referencia}</Heading>
                      <Box mt={5} fontSize={16} fontWeight={300} w="100%" textAlign="center">
                        <Text mb={10}>Deseja realmente exluir está referência ?</Text>
                        <Flex justifyContent="center">
                          <Button bg="green.400" color="#FFF">Confirmar</Button>
                          <Button bg="red.400" color="#FFF" ml={5}>Cancelar</Button>
                        </Flex>
                      </Box>

                      <Alert mt={10} status='success' variant='left-accent'>
                        <AlertIcon />
                        Referência excluida com sucesso!
                      </Alert>

                   </Center>
    </DrawerView>
  )
}

export default ReferenciasExcluir
