import React, { useState } from 'react'
import {Alert, AlertIcon, Box, Button, Flex, FormControl, FormLabel, Input, ScaleFade } from '@chakra-ui/react'
import { DrawerView } from '../../../../Components'
import { RequestAPI } from '../../../../Configurations'
import { useGetTokenLogin } from '../../../../Hooks'
import { MdFileDownload, MdFilePresent } from 'react-icons/md'
import { useFormik } from 'formik'

function PagamentosRelatorios() {

  const getValueLogin = useGetTokenLogin();
  const [textDisplay, setTextDisplay] = useState({status:"loading", text:"Aguarde o processo!"})
  const [loader, setLoader] = useState(false)

  const formik = useFormik({
    initialValues:{
      num_referencia:'',
      dia_inicio:'',
      dia_final:'',
      Identificacao_Log_EGR: '',
      numero_Log_EGR: '',
      hora_do_movimento: '',
      data_movimento: '',
      montante_da_operacao: '',
    },
    onSubmit: async (values)=> {

      try {
      
        setLoader(true)   
  
        const {data:{status, mensagem}} = await RequestAPI.post(`/relatorios`,{...values, entidade: getValueLogin.numero_entidade, "tipo": "Pagamentos", "campos": values})
        setTextDisplay({status:"loading", text:"Aguarde o processo!"})
        
        if(status === "sucesso"){

          setTextDisplay({status:"success", text: `Geramos com sucesso`})
          window.open(`https://qa.api.fluxosfinanceiro.com/v1/reports/`+mensagem.filename)
          setLoader(false)
          
         }else{
           setTextDisplay({status:"error", text: mensagem})
      
        }
      
      }catch (error) {
        console.log(error)
        setTextDisplay({status:"error", text:"Não conseguimos ter sucesso no processo, Tente outra vez!"})
      }
      
      
    },
  })

  return (
    <DrawerView Titulo={"Gerar relatório de pagamentos "} tamanho={"lg"} Botao={<Button bg="#006672" color="#FFFFFF" fontWeight={300} leftIcon={<MdFilePresent/>} mb={10} ml={5}>Relatorios</Button>}>
                      <form onSubmit={formik.handleSubmit}>
                    <Box>
                      <Flex>
                        <FormControl mt={2}>
                            <FormLabel>Por referência</FormLabel>
                              <Input type='text'  name='num_referencia' onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                        <FormControl mt={2} ml={4}>
                            <FormLabel>Valor</FormLabel>
                              <Input type='number'  name='montante_da_operacao' onChange={formik.handleChange} value={formik.values.montante_da_operacao} />
                        </FormControl>
                      </Flex>
                      <Flex>                      
                        <FormControl mt={2}>
                              <FormLabel>Data inicial</FormLabel>
                              <Input type='date'  name='dia_inicio'  required onChange={formik.handleChange} value={formik.values.dia_inicio} />
                        </FormControl>
                        <FormControl mt={2} ml={4}>
                              <FormLabel>Data final</FormLabel>
                              <Input type='date' name='dia_final' onChange={formik.handleChange} required value={formik.values.dia_final} />
                        </FormControl>
                      </Flex>                      
                      <Flex>
                        <FormControl mt={2}>
                                  <FormLabel>Periodo</FormLabel>
                                  <Input type='number' name='Identificacao_Log_EGR' onChange={formik.handleChange} value={formik.values.Identificacao_Log_EGR} />
                        </FormControl>                       
                        <FormControl mt={2} ml={4}>
                            <FormLabel>Transação</FormLabel>
                              <Input type='number'  name='numero_Log_EGR' onChange={formik.handleChange} value={formik.values.numero_Log_EGR} />
                        </FormControl>     
                        {/* <FormControl mt={2} ml={4}>
                          <FormLabel>Terminal</FormLabel>
                          <Select placeholder={'Selecione o terminal'}  name='tipo_de_terminal' value={formik.values.tipo_de_terminal} onChange={formik.handleChange}>
                            <option value="A">ATM</option>
                            <option value="M">Multicaixa Express</option>
                            <option value="1">Internet Banking</option>
                          </Select>
                        </FormControl>                      */}
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <FormControl mt={4}>
                              <FormLabel>Data</FormLabel>
                              <Input type='date' name='data_movimento'  
                                onChange={formik.handleChange}
                                value={formik.values.data_movimento} />
                          </FormControl>
                        <FormControl mt={4} ml={4}>
                              <FormLabel>Horas</FormLabel>
                              <Input type='time' name='hora_do_movimento'  
                                onChange={formik.handleChange}
                                value={formik.values.hora_do_movimento} />
                          </FormControl>
                      </Flex>
                      
                      <Button leftIcon={<MdFileDownload/>} bg="#006672" color="#FFFFFF"  type='submit' mt={5}>Buscar</Button>
                  
                  </Box> 
                    </form>

              <ScaleFade initialScale={0.9} in={loader} transition={{ exit: { delay: 1 }, enter: { duration: 0.5 } }}>
                <Alert mt={10} p={[3]} status={textDisplay.status}  width={["xs","lg","xl"]} variant='left-accent'>
                  <AlertIcon />
                  {textDisplay.text}
                </Alert>
              </ScaleFade>
                      
    </DrawerView>
  )
}

export default PagamentosRelatorios
