import {Box, Center, TableContainer, Flex, Show} from '@chakra-ui/react'
import React from 'react'
import {Footer, Header, TituloPagina} from '../../Components';
import {PagamentosFiltros, PagamentosLista, PagamentosPaginacao, PagamentosRelatorios} from './Complementos';



function Pagamentos() {
  
  
  return (
    <Box>
        <Header pagina="Dashboard"/> 
        <TituloPagina titulo="Transações" descricao="Monitore em tempo real seus pagamentos chegando até voçê!"/>        
        <Center my={20} display="flex" justifyContent="space-evenly" alignItems="center" flexDirection={["column","row"]}>
        <TableContainer>
        <Show above='sm'>
          <Flex justifyContent="space-between">
            <PagamentosPaginacao/>
            <Flex>
              <PagamentosFiltros/>
              <PagamentosRelatorios/>
            </Flex>
          </Flex>
          </Show>
          <PagamentosLista/>
          </TableContainer>

        </Center>

        <Footer/>
    </Box>
  )
}

export default Pagamentos
