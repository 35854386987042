import { Avatar, Box, Center, Flex, Hide, Show, Square, Tag, TagLabel, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {LogoMarcaWhite, MudarTemaIcone} from '..';
import { MdLogout, MdMonetizationOn, MdSettings } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { FaIdCardAlt } from 'react-icons/fa';
import { AiFillDashboard, AiFillSignal } from 'react-icons/ai';
import { useGetTokenLogin, useLogout } from '../../Hooks';


function Header() {
    const [color, setColor] = useState('')
    const getValueLogin = useGetTokenLogin();
    const sairDoSistema =  useLogout;
    const navigate =  useNavigate()

    useEffect(()=>{
        if(!getValueLogin.numero_entidade){
            navigate('/')
        }
    },[getValueLogin, navigate])

  return (
    <Box>
        <Flex color='white' bg="#000" boxSizing='border-box' alignItems="center" justifyContent="space-between">
            <Center w='100px' p={2}>
                <LogoMarcaWhite/>
            </Center>
            <Show above='sm'>
            <Square>
                <Tag size='lg' colorScheme='red' borderRadius='full'>
                    <Avatar
                        src={`https://qa.api.fluxosfinanceiro.com/v1/images/${getValueLogin.logo}`}
                        size='xs'
                        name={getValueLogin.responsavel}
                        ml={1}
                        mr={2}
                    />
                    <TagLabel>{getValueLogin.nome_empresa}</TagLabel>
                </Tag>
            </Square>
            </Show>
            <Hide above='sm'>
            <Square>
                    <Avatar
                        src={`https://qa.api.fluxosfinanceiro.com/v1/images/${getValueLogin.logo}`}
                        size='sm'
                        name={getValueLogin.responsavel}
                        ml={1}
                        mr={2}
                    />
            </Square>
            </Hide>
            <Show above='sm'>
                <Flex flex={1} size='40px' justifyContent="flex-end" pr={10}>
                    {/* <Text mr={5} display="flex" justifyContent="space-between" alignItems="center"><MdNotifications size={20}/><Link to={"/notificacoes"}> &nbsp;&nbsp;&nbsp;&nbsp;Notificações</Link></Text> */}
                    <Text mr={5} display="flex" justifyContent="space-between" alignItems="center"><MdSettings size={20}/><Link to={"/configuracoes"}> &nbsp;&nbsp;&nbsp;&nbsp;Configurações</Link></Text>
                    <Text onClick={() => sairDoSistema(getValueLogin.numero_entidade)} cursor="pointer" mr={5} display="flex" justifyContent="space-between" alignItems="center"><MdLogout size={20}/>&nbsp;&nbsp;&nbsp;&nbsp;Sair</Text>
                </Flex>
                <MudarTemaIcone colorText={setColor} color={color}/>
            </Show>
            <Hide above='sm'>
                <Flex flex={1} size='40px' justifyContent="flex-end" pr={10}>
                    {/* <Text mr={5} display="flex" justifyContent="space-between" alignItems="center"><Link to={"/notificacoes"}><MdNotifications size={20}/></Link></Text> */}
                    <Text mr={5} display="flex" justifyContent="space-between" alignItems="center"><Link to={"/configuracoes"}><MdSettings size={20}/></Link></Text>
                    <Text onClick={() => sairDoSistema(getValueLogin.numero_entidade)} cursor="pointer" mr={5} display="flex" justifyContent="space-between" alignItems="center"><MdLogout size={20}/></Text>
                </Flex>
                <MudarTemaIcone colorText={setColor} color={color}/>
            </Hide>
        </Flex>
        <Show above='sm'>
            <Flex color='white' bg="#006672" justifyContent="center" alignItems="center" p={3} fontSize={16} fontWeight={500}>
                <Text mr={20} display="flex" justifyContent="space-between" alignItems="center"><AiFillDashboard size={30}/><Link to={"/dashboard"}> &nbsp;&nbsp;&nbsp;&nbsp;Dashboard</Link></Text>
                <Text mr={20} display="flex" justifyContent="space-between" alignItems="center"><MdMonetizationOn size={30}/><Link to={"/movimentos"}> &nbsp;&nbsp;&nbsp;&nbsp;Transações</Link></Text>
                <Text mr={20} display="flex" justifyContent="space-between" alignItems="center"><AiFillSignal size={30}/><Link  to={"/periodos"}> &nbsp;&nbsp;&nbsp;&nbsp;Periodos</Link></Text>
                <Text display="flex" justifyContent="space-between" alignItems="center"><FaIdCardAlt size={25}/><Link to={"/referencias"}> &nbsp;&nbsp;&nbsp;&nbsp;Referências</Link></Text>
            </Flex>
        </Show>
        <Hide above='sm'>
            <Flex color='white' bg="#006672" justifyContent="center" alignItems="center" p={3} fontSize={16} fontWeight={500}>
                <Text mr={10} display="flex" justifyContent="space-between" alignItems="center"><Link to={"/dashboard"}><AiFillDashboard size={30}/></Link></Text>
                <Text mr={10} display="flex" justifyContent="space-between" alignItems="center"><Link to={"/movimentos"}> <MdMonetizationOn size={30}/></Link></Text>
                <Text mr={10} display="flex" justifyContent="space-between" alignItems="center"><Link to={"/periodos"}><AiFillSignal size={30}/></Link></Text>
                <Text display="flex" justifyContent="space-between" alignItems="center"><Link to={"/referencias"}><FaIdCardAlt size={30}/></Link></Text>
            </Flex>
        </Hide>
    </Box>
  )
}

export default Header
