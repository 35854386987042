import React from 'react'
import {Box, Button, Flex, FormControl, FormLabel, Heading, Input} from '@chakra-ui/react'
import { MdOutlineHomeWork, MdSave } from 'react-icons/md';
import { useFormik } from 'formik';

function ConfiguracoesEmpresa() {
    
    const formik = useFormik({
        initialValues:{
          num_referencia:'',
            data_limite_pagamento:'',
            estado_atm:'Activo',
            montante_fixo:''
        },
        onSubmit: async (values)=> {
    
        },
      })
  return (
    <Box px={[10]} w={["100%","60%","70%"]} my={10}>
                <Heading display="flex" alignItems="center" fontSize={20}><MdOutlineHomeWork size={30} /> &nbsp;&nbsp;&nbsp;&nbsp;Configurações da empresa</Heading>

                <form onSubmit={formik.handleSubmit}>
                    <Box alignItems="center" display="flex" flexDirection="column">
                      
                      <Flex w={["100%","60%","70%"]} mt={[5,5]} flexDirection={["column","row"]}>
                        <FormControl mt={4} >
                              <FormLabel>Nome da empresa *</FormLabel>
                              <Input type='text' flex={1}  name='num_referencia'  required onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                        <FormControl mt={4} ml={[0,4]}>
                              <FormLabel>Pessoa de contato</FormLabel>
                              <Input type='text' flex={1}  name='num_referencia'  required onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                      </Flex>
                      <Flex w={["100%","80%","70%"]} mt={[0,5]} flexDirection={["column","row"]}>
                        <FormControl mt={4}>
                              <FormLabel>Endereço</FormLabel>
                              <Input type='text' flex={1}  name='num_referencia'  required onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                      </Flex>                      
                      <Flex w={["100%","80%","70%"]} mt={[0,5]} flexDirection={["column","row"]}>
                          <FormControl mt={4}>
                          <FormLabel>E-mail</FormLabel>
                            <Input type='date'  name='data_limite_pagamento' required onChange={formik.handleChange} value={formik.values.data_limite_pagamento} />
                          </FormControl>
                          <FormControl mt={4} ml={[0,4]}>
                            <FormLabel>Número de telefone</FormLabel>
                            <Input type='number'  name='montante_fixo' onChange={formik.handleChange} value={formik.values.montante_fixo} />                              
                          </FormControl>
                      </Flex>
                      <Flex w={["100%","80%","70%"]} mt={[0,5]} flexDirection={["column","row"]}>
                          <FormControl mt={4}>
                          <FormLabel>Número de telemóvel alternativo</FormLabel>
                            <Input type='date'  name='data_limite_pagamento' required onChange={formik.handleChange} value={formik.values.data_limite_pagamento} />
                          </FormControl>
                          <FormControl mt={4} ml={[0,4]}>
                            <FormLabel>Fax</FormLabel>
                            <Input type='number'  name='montante_fixo' onChange={formik.handleChange} value={formik.values.montante_fixo} />                              
                          </FormControl>
                      </Flex>
                      <Flex w={["100%","80%","70%"]} mt={[0,5]} flexDirection={["column","row"]}>
                        <FormControl mt={4}>
                              <FormLabel>URL do site</FormLabel>
                              <Input type='text' flex={1}  name='num_referencia'  required onChange={formik.handleChange} value={formik.values.num_referencia} />
                        </FormControl>
                      </Flex>
                      
                      <Button leftIcon={<MdSave/>} bg="#006672" color="#FFFFFF"  type='submit' mt={[5,5]}>Salvar</Button>
                  
                  </Box> 
                </form>                
                
             </Box>
  )
}

export default ConfiguracoesEmpresa
