import React from 'react'
import {Table, Tbody, Td, Th, Thead, Tr, Flex, Show, Tag, Center, Alert, AlertIcon } from '@chakra-ui/react'
import {ReferenciasDetalhes, ReferenciasExcluir, ReferenciasEditar} from '../'
import { Link } from 'react-router-dom'
import useSWR from 'swr';
import { RequestAPI } from '../../../../Configurations';
import { useGetTokenLogin } from '../../../../Hooks';
import { SkeletonLoader } from '../../../../Components';
import { useStoreReferencias } from '../../../../Stores';

function ReferenciasLista() {

  const getValueLogin = useGetTokenLogin();
  const {referencias} = useStoreReferencias()

  const fetcher = async (url) =>{
    const {data} = await RequestAPI.get(url)

    return data
  }

  const { data, error, isLoading } = useSWR(`/referencias/entidade/${getValueLogin.numero_entidade}`, fetcher)

  const result = referencias.length ? referencias : data?.mensagem

  return (
    error ? <Center>
      <Alert p={[5]} status={"error"}  width={["xs","lg","3xl"]} variant='left-accent'>
      <AlertIcon />
      Não conseguimos carregar suas informações, por favor recarregue a pagina!
      </Alert>
      </Center>
      : isLoading ? <SkeletonLoader/> :
    <Table variant='simple' fontSize={[12,14,16]}>
                <Thead>
                  <Tr>
                    <Th fontSize={[12,14,16]}>Referência</Th>
                    <Show above='sm'>
                    <Th fontSize={[12,14,16]}>Estado</Th>
                    <Th fontSize={[12,14,16]}>Produto</Th>
                    <Th fontSize={[12,14,16]}>Limite de pagamento</Th>
                    <Th fontSize={[12,14,16]}>Gerado</Th>
                    </Show>
                    <Th fontSize={[12,14,16]}>Mais</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {result.map((value,i) => <Tr key={i}>
                    <Td _hover={{color:"#006672"}}><Link to={`/movimentos/referenciado/${value.num_referencia}`}>{value.num_referencia}</Link></Td>
                    <Show above='sm'>
                    <Td><Tag colorScheme={value.estado_atm === "Activo" ? "green" : value.estado_atm === "Inactivo" ? "red": "yellow"}>{value.estado_atm}</Tag></Td>
                    <Td>{value.produto}</Td>
                    <Td>{new Date(value.data_limite_pagamento).toLocaleDateString()}</Td>
                    <Td>{new Date(value.criada_r).toLocaleDateString()} {new Date(value.criada_r).toLocaleTimeString()}</Td>
                    </Show>
                    <Td w={[200,240]}>
                      <Flex justifyContent="space-between">
                        <ReferenciasDetalhes data={value}/>
                        <ReferenciasExcluir data={value}/>
                        <ReferenciasEditar data={value}/>
                      </Flex>
                    </Td>
                  </Tr> 
                )}
                </Tbody>
              </Table>
  )
}

export default ReferenciasLista
