import { Alert, AlertIcon, Box, Center, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import Chart from 'react-apexcharts'
import useSWR from 'swr'
import { useGetTokenLogin } from '../../../../Hooks'
import { RequestAPI } from '../../../../Configurations'
import { SkeletonLoader } from '../../../../Components'

function DashboardGrafico() {
  const bg = useColorModeValue('#FFF', '#1A202C')
  const getValueLogin = useGetTokenLogin();

  const fetcher = async (url) =>{
    const {data} = await RequestAPI.get(url)

    return data
  }

  const { data, error, isLoading } = useSWR(`/dashboard/entidade/${getValueLogin.numero_entidade}`, fetcher)


  return (
    <Center mt={0}>
     <Box justifyContent="center" mt={30} rounded={5} className="donut" w={["100%","90%"]}>
         {error ? <Center>
                     <Alert p={[5]} status={"error"}  width={["xs","lg","3xl"]} variant='left-accent'>
                       <AlertIcon />
                       Não conseguimos carregar suas informações, por favor recarregue a pagina!
                     </Alert>
                   </Center>
         : isLoading ? <SkeletonLoader/> : <Chart options={{
                 chart: {
                   id: "basic-bar",
                   stacked: true,
                   background:bg
                 },
                 xaxis: {
                   categories: ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"]
                 },
                 stroke:{
                   curve: "smooth",
                   width:2,
                   lineCap: "butt",
                   colors:["#006672"]
                 }
               }} series={[
                   {
                     name:"Montante",
                     data: data?.mensagem?.somatorioMensais?.soma
                   }
               ]
               } type="area"  width="100%" height={400}/>
            }
     </Box>
  </Center>
  )
}

export default DashboardGrafico
