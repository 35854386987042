import React from 'react'
import { Button, useColorMode, useColorModeValue } from '@chakra-ui/react'


function LogoMarca({colorText, bgColor = ()=>{}}) {

  const { colorMode, toggleColorMode } = useColorMode()
  const color = useColorModeValue('#006672', '#006672')
  const bg = useColorModeValue('#FFF', '#011627')

  bgColor(bg)
  colorText(color)

  const changeColors = () => {

    bgColor(bg)
    colorText(color)
    toggleColorMode()
    // #DEEAFF
  }

  return (
    <div>
      <Button mb={10} onClick={changeColors}  color={color} bg={bg}>
        Mudar para {colorMode === 'light' ? 'Dark' : 'Light'}
      </Button>
    </div>
  )
}

export default LogoMarca
